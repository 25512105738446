const SocialIcons = () => {
  const styles = {
    icon: {
      textDecoration: "none",
      fontSize: "22px",
      padding: "10px",
      transition: "0.2s ease-in",
    },
  };

  return (
    <div className="socialIcons" style={styles.socialIcons}>
      <a className="icon" style={styles.icon} href="https://github.com/Pradipbhandari26">
        <i className="fa-brands fa-github" aria-hidden="true" title="Pradip Bhandari' GitHub Profile"></i>
      </a>
      <a className="icon" style={styles.icon} href="https://www.linkedin.com/in/pradip-bhandari-072825120/">
        <i className="fa-brands fa-linkedin" aria-hidden="true" title="Pradip Bhandari' LinkedIn Profile"></i>
      </a>
      <a className="icon" style={styles.icon} href="https://www.instagram.com/_pradip_bhandari/">
        <i className="fa-brands fa-instagram" aria-hidden="true" title="Pradip Bhandari' Instagram Profile"></i>
      </a>
      <a className="icon" style={styles.icon} href="https://twitter.com/pradipbhandar">
        <i className="fa-brands fa-twitter" aria-hidden="true" title="Pradip Bhandari' Twitter Profile"></i>
      </a>
    </div>
  );
};

export default SocialIcons;
